var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3 mt-6"},[_vm._m(0),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_vm._l((_vm.documents),function(item,index){return _c('div',{key:index,staticClass:"d-flex flex-row"},[_c('validation-provider',{staticStyle:{"width":"100%"},attrs:{"rules":{
          regex: /^((?:https?:)?\/\/)?((?:www)\.)?((?:(.*)\.com))(.*)/,
          required: true
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('a-text-field',{attrs:{"readonly":_vm.viewOnly,"error-messages":errors,"styling":{
            xLarge: true,
            label: 'enter document Link',
            class: 'student-research-link-input',
            outlined: true,
            rounded: true
          }},model:{value:(item.url),callback:function ($$v) {_vm.$set(item, "url", $$v)},expression:"item.url"}})]}}],null,true)}),(_vm.documents.length > 1)?_c('a-btn',{attrs:{"disabled":_vm.viewOnly,"styling":{
          rounded: true,
          large: true,
          class: 'mt-3'
        },"icon":""},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-trash-can")])],1):_vm._e()],1)}),_c('a-btn',{attrs:{"disabled":_vm.viewOnly || invalid,"styling":{
        rounded: true,
        xLarge: true,
        color: 'green',
        class: 'module-edit__add-button white--text font-weight-bold',
        depressed: true,
        outlined: true
      }},on:{"click":function($event){return _vm.populate()}}},[_c('v-icon',{staticClass:"module-edit__add-icon font-weight-bold mr-1"},[_vm._v("mdi-plus")]),_vm._v(" Add Document Link ")],1)]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-center"},[_c('h2',{staticClass:"mb-6"},[_vm._v("Documents")])])}]

export { render, staticRenderFns }